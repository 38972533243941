import toast from '../components/Toast'
const pako = require('pako')

const api = process.env.REACT_APP_API

export default async function lambdaFetch(
  path,
  method,
  webToken,
  body,
  successMessage,
  creds,
  dontShowTimeoutError,
  customErrorMessage
) {
  try {
    const api_key = creds?.apiKey || process.env.REACT_APP_API_X_KEY || ''


    const res = await fetch(`${api}/${path}`, {
      timeout: 6000,
      method: method,
      headers: {
        'X-Api-Key': api_key,
        'Content-Type': 'application/json',
        Authorization: `Bearer ${webToken}`
      },
      body: body
    }).then(resp => {
      if (!resp.ok) {
        if (resp.status === 401) {
          creds.login() 
          throw new Error('Your access token has expired. Please log in again.')
        }
        if (resp.status === 504) {
          throw new Error('Timeout error')
        }
        throw new Error(`Error with status: ${resp.status}`)
      }
      return resp.json()
    })

    if (res.isPacko) {
      const unpackedData = JSON.parse(pako.inflate(res.data, { to: 'string' }))
      res.data = unpackedData
    }

    if(res?.data?.response?.statusCode == 500) {
      const body = res?.data?.response?.body
      console.log({body})
      const error = JSON.parse(body)?.error
      toast.persist(error ? error : error.toString(), 'error')
      return false
    }

    if (
      res.message ===
      'User is not authorized to access this resource with an explicit deny'
    ) {
      if (path === 'login') {
        return null
      }
      toast.persist(
        'Your access token may be expired. Please try logging back in.',
        'error'
      )
      creds.login()
      return null
    }

    if (res.message === 'Endpoint request timed out' && !dontShowTimeoutError) {
      toast.persist('Timeout error', 'error')
      return false
    }
    if (!res.success && res.error) {
      toast.persist(res.error, 'error')
      return false
    } else if (res.success && res.data) {
      if (res.data.maxRows) {
        toast.persist(res.data.maxRows, 'warning')
      }
      return res
    } else {
      successMessage && toast.success(successMessage)
      return res
    }
  } catch (e) {
    console.log("fetching error: ", e)
    toast.persist(customErrorMessage ? customErrorMessage : e.toString(), 'error')
  }
}
