import React, { useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import {
    IconButton,
    Typography,
    Grid,
    TextField,
    Paper,
    InputAdornment,
    Button
} from '@material-ui/core'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import LoadingButton from '../components/LoadingButton'
import { useInvoiceMatchingTableCols, useInvoiceMatchReactTableCols } from '../hooks/VendorCols'
import { smallScreenWidth } from '../constants/AppConstants'
import ServerSideAutoCompletev2 from '../functions/ServerSideAutoCompletev2'
import AutocompleteDropDown from '../components/AutocompleteDropdown'
import { DashboardAthenaTableServerSideNoSelect } from '../components/DashboardAthenaTableServerSideNoSelect'
import fetchFromAthena from '../functions/FetchFromAthena'
import ReactTable from '../components/ReactTable'
import { printValue, printValueInline } from '../utils/PrintValue'
import Matching from '../components/Matching';

const _ = require('lodash')

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        margin: '2rem 1rem',
    },
    paper: {
        padding: theme.spacing(2),
        color: 'rgba(0,0,0,0.9)',
        height: '100%',
    },
    descript: { fontSize: '0.8rem', color: '#545454' },
    descriptVal: { fontSize: '0.85rem' },
}));

const startAdornment = (
    <>
        <InputAdornment position='start'>
            <div></div>
        </InputAdornment>
    </>
)
const defaultHeaders = {
    company: '',
    vendor: '',
    invoice: '',
    suffix: ''
}

export default function InvoiceMatching(props) {
    const [state, setstate] = useState({
        isLoading: false,
        data: null,
        fetched: true,
        tableKey: JSON.stringify(defaultHeaders)
    })
    const [showTable, setShowTable] = useState(false)
    const [headers, setHeaders] = useState(defaultHeaders)
    const [clearHeader, setClearHeaders] = React.useState(false)
    const [invoiceHeaders, setInvoiceHeaders] = React.useState({})
    const [invoiceDetails, setInvoiceDetails] = React.useState([])
    const [matchData, setMatchData] = useState({
        isOpen: false,
        isLoading: true
    })
    const classes = useStyles()

    const { credentials } = props.fetchInitialData
    const invoiceMatchingReactTableCols = useInvoiceMatchingTableCols()
    const invoiceMatchReactTableCols = useInvoiceMatchReactTableCols()
    const handleSubmit = async (e) => {
        e.preventDefault();
        if (JSON.stringify(headers) === state.tableKey) return null
        // // getHeader()
        // setShowTable(true);
        setstate({ ...state, isLoading: true, tableKey: JSON.stringify(headers) });

        const queries = ['invoice_matching_read', 'invoice_matching_lines']

        const data = await Promise.allSettled(
            queries.map(query =>
                fetchFromAthena({
                    queryName: query,
                    headers: {
                        company: headers.company,
                        vendor: headers.vendor,
                        invoice: headers.invoice,
                        suffix: headers.suffix || 0
                    },
                    accessToken: credentials.user.accessToken,
                    credentials: credentials
                })
            )
        ).then(results => {
            return results
        })

        setstate({ ...state, isLoading: false });

        setInvoiceHeaders(data[0].value[0])
        setInvoiceDetails(data[1].value)
        setShowTable(true)

    };
    const getMatchData = async (headers) => {
        try {
            setMatchData({
                ...matchData,
                isOpen: true,
                isLoading: true
            })

            const queries = ['invoice_matching_line_header', 'invoice_matching_line_details']

            const data = await Promise.allSettled(
                queries.map(query =>
                    fetchFromAthena({
                        queryName: query,
                        headers: headers,
                        accessToken: props.fetchInitialData.credentials.user.accessToken,
                        credentials: props.fetchInitialData.credentials
                    })
                )
            ).then(results => {
                return results
            })
            setMatchData({
                ...matchData,
                isOpen: true,
                isLoading: false,
                matchHeader: { ...headers, ...data[0].value[0] },
                matchDetails: data[1].value,
            })

        } catch (err) {
            console.log(err)
        }
    }

    const invoiceLineData = invoiceDetails.map(p => {

        return {
            ...p,
            MATCH_LINK: (
                <div className='editLink' onClick={() => getMatchData({
                    company: p.COMPANY,
                    vendor: p.VENDOR,
                    invoice: p.INVOICE,
                    suffix: p.SUFFIX,
                    lineNbr: p.LINE_NBR,
                    poNumber: invoiceHeaders.PO_NUMBER,
                    poCode: invoiceHeaders.PO_CODE,
                    poRelease: invoiceHeaders.PO_RELEASE
                })}>
                    {p.MATCHED_QTY}
                </div>
            )
        }
    })
    const { matchHeader, matchDetails } = matchData
    return (
        <>
            <Matching
                isOpen={!!matchData.isOpen}
                isLoading={!!matchData.isLoading}
                handleClose={() => setMatchData({
                    ...matchData,
                    isOpen: false
                })}
                headerData={matchData.matchHeader ? matchData.matchHeader : {}}
                tableData={matchData.matchDetails ? matchData.matchDetails : []}
                cols={invoiceMatchReactTableCols}
                fileName={`invoice_matching`}
                noFooter
                reactTable
                contextText={
                    matchData.matchHeader ? (
                        <Grid
                            container
                            spacing={1}
                            direction="row"
                            justifyContent="flex-start"
                            alignItems="stretch"
                        >
                            <Grid item xs={12} sm={12} md={12}>
                                <Paper elevation={3} style={{ padding: "1rem" }}>
                                    <Grid
                                        container
                                        spacing={1}
                                        direction="row"
                                        justifyContent="flex-start"
                                        alignItems="flex-start"
                                    >
                                        <Grid item xs={12}>
                                            <div
                                                style={{
                                                    display: 'flex',
                                                    marginTop: '0.5rem',
                                                    justifyContent: 'flex-start',
                                                }}
                                            >




                                                <div>
                                                    <Typography variant='h6'>{`Company: ${matchHeader.company}`}</Typography>
                                                    {printValueInline('Po Number', matchHeader.PO_NUMBER)}
                                                    {printValueInline('Rel', matchHeader.PO_RELEASE)}
                                                    {printValueInline('Code', matchHeader.PO_CODE)}
                                                    {printValueInline('Line', matchHeader.lineNbr)}
                                                    <br />
                                                    {printValue(matchData.matchHeader, 'vendor', 'Vendor')}

                                                </div>
                                            </div>
                                        </Grid>

                                        <Grid item xs={12} sm={12}>
                                            {printValue(matchData.matchHeader, 'ITEM', 'Item', 'ITEM_DESC')}
                                        </Grid>
                                        <Grid item xs={12} sm={3}>
                                            {printValue(matchData.matchHeader, 'ENTERED_QTY', 'Entered Qty')}
                                            {printValue(matchData.matchHeader, 'MATCHED_QTY', 'Matched Qty')}
                                        </Grid>
                                        <Grid item xs={12} sm={3}>
                                            {printValue(matchData.matchHeader, 'ENT_UOM', 'OUM')}
                                        </Grid>
                                        <Grid item xs={12} sm={3}>
                                            {printValue(matchData.matchHeader, 'ENT_UNIT_CST', 'Unit Cost', '', '', 'currency')}
                                            {printValue(matchData.matchHeader, 'TOT_BASE_AMT', 'Base Amt', '', '', 'currency')}
                                        </Grid>
                                    </Grid>
                                </Paper>
                            </Grid>
                        </Grid>
                    ) : ''
                }
            />

            <div style={{ padding: '1.5rem 0.5rem' }}>
                <IconButton
                    style={{ borderRadius: '5px', position: 'absolute' }}
                    size='small'
                    onClick={() => props.history.push('/')}
                    aria-label='delete'
                >
                    <ArrowBackIcon />
                    {props.fetchInitialData.credentials.appWidth > smallScreenWidth &&
                        'Dashboard'}
                </IconButton>
                <Typography variant='h5' style={{ textAlign: 'center' }}>
                    Invoice Matching
                </Typography>
                <div className={classes.root}>
                    <Paper elevation={0} style={{ padding: '1rem' }}>
                        <form onSubmit={handleSubmit}>
                            <Grid
                                container
                                alignContent='center'
                                alignItems='flex-start'
                                spacing={3}
                            >
                                <Grid item>
                                    <AutocompleteDropDown
                                        {...props}
                                        id={'company'}
                                        name={'Company'}
                                        width='125px'
                                        key={clearHeader}
                                        required={true}
                                        variant='standard'
                                        label={'Company'}
                                        value={headers.company}
                                        category={'company'}
                                        screen={'ap'}
                                        onChange={val =>
                                            setHeaders({
                                                ...headers,
                                                company: val ? val.key_value : ''
                                            })
                                        }
                                    />
                                </Grid>

                                <Grid item>

                                <ServerSideAutoCompletev2
                                    required={true}
                                    id={'vendor'}
                                    label={'Vendor'}
                                    dontAutoHighlight
                                    dontOpenOnFocus
                                    fullWidth
                                    margin={'standard'}
                                    padding={'8px'}
                                    accessToken={
                                    props.fetchInitialData.credentials.user.accessToken
                                    }
                                    error={`No Vendor Found`}
                                    width="160px"
                                    variant='standard'
                                    handleChange={(id, hit) => {
                                        setHeaders({
                                            ...headers,
                                            vendor: id ? id : ''
                                        })
                                    }}
                                    credentials={props.fetchInitialData.credentials}
                                    apiResource='autocomplete'
                                    secondaryParams={{
                                    category: 'ap',
                                    dropdown: 'vendor',
                                    action: 'autocomplete-serverside-select'
                                    }}
                                />


                                    {/* <AutocompleteDropDown
                                        disabled={!headers.company}
                                        {...props}
                                        id={'vendor'}
                                        name={'Vendor'}
                                        width='125px'
                                        key={clearHeader}
                                        required={true}
                                        variant='standard'
                                        label={'Vendor'}
                                        value={headers.vendor}
                                        category={'vendor'}
                                        screen={'ap'}
                                        onChange={val =>
                                            setHeaders({
                                                ...headers,
                                                company: val ? val.key_value : ''
                                            })
                                        }
                                    /> */}
                                </Grid>

                                <Grid item>
                                    <ServerSideAutoCompletev2
                                        disabled={!headers.vendor}
                                        required={true}
                                        id='ivoice'
                                        width='175px'
                                        label='Invoice'
                                        accessToken={credentials.user.accessToken}
                                        error='No Invoice Found'
                                        variant='standard'
                                        handleChange={(id, hit) => {
                                            setHeaders({ ...headers, invoice: id })
                                        }}
                                        credentials={credentials}
                                        apiResource='vendors'
                                        secondaryParams={{
                                            action: 'autocomplete-invoice-number',
                                            limit: 100,
                                            company: headers.company,
                                            vendor: headers.vendor
                                        }}
                                        dontOpenOnFocus
                                    />
                                </Grid>
                                <Grid item style={{ marginTop: '5px' }}>
                                    <TextField
                                        id='suffix'
                                        label='Suffix'
                                        style={{ width: '125px' }}
                                        value={headers.suffix}
                                        onChange={e =>
                                            setHeaders({ ...headers, suffix: e.target.value })
                                        }
                                        InputProps={{
                                            startAdornment: (
                                                <>
                                                    <InputAdornment position='start'>
                                                        <div></div>
                                                    </InputAdornment>
                                                </>
                                            )
                                        }}
                                    />
                                </Grid>
                            </Grid>

                            <div style={{ marginTop: '1rem' }}>
                                <LoadingButton
                                    label='Submit'
                                    isLoading={state.isLoading}
                                    color='primaryVLButton'
                                    buttonType='submit'
                                />
                                {!!(
                                    headers.company ||
                                    headers.vendor ||
                                    headers.invoice ||
                                    headers.suffix
                                ) && (
                                        <Button
                                            onClick={() => {
                                                setClearHeaders(!clearHeader)
                                                setHeaders(defaultHeaders)
                                            }}
                                            style={{ marginLeft: '0.5rem' }}
                                        >
                                            {' '}
                                            Clear
                                        </Button>
                                    )}
                            </div>
                        </form>
                    </Paper>

                    <div style={{ marginTop: '1rem' }}>
                        {showTable && (
                            <>
                                <Grid
                                    container
                                    spacing={2}
                                    direction="row"
                                    justifyContent="flex-start"
                                    alignItems="stretch"
                                >
                                    <Grid item xs={12} sm={12} md={12}>
                                        <Paper elevation={2} className={classes.paper}>
                                            <Grid
                                                container
                                                spacing={0}
                                                direction="row"
                                                justifyContent="space-evenly"
                                                alignItems="flex-start"
                                            >
                                                <Grid item xs={12}>
                                                    <div
                                                        style={{
                                                            display: 'flex',
                                                            marginTop: '0.5rem',
                                                            justifyContent: 'flex-start',
                                                        }}
                                                    >
                                                        <div>
                                                            <Typography variant='h5'>{`Company: ${invoiceHeaders.COMPANY}`}</Typography>
                                                            <Typography variant='subtitle1'>{`Invoice: ${invoiceHeaders.INVOICE}`}</Typography>
                                                            <Typography variant='subtitle2'>{`Type: ${invoiceHeaders.INVOICE_TYPE}`}</Typography>
                                                            {printValue(invoiceHeaders, 'INVOICE_DTE', 'Invoice Date')}
                                                        </div>
                                                    </div>
                                                </Grid>
                                                <Grid item xs={12} sm={4}>
                                                    {printValue(invoiceHeaders, 'ACCR_CODE', 'Accr Code')}
                                                    {printValue(invoiceHeaders, 'AUTH_CODE', 'Auth Code')}
                                                    {printValue(invoiceHeaders, 'OBJ_ID', 'Object ID')}
                                                    {printValue(invoiceHeaders, 'OPERATOR', 'Operator')}
                                                    {printValue(invoiceHeaders, 'DESCRIPTION', 'Description')}
                                                    {printValue(invoiceHeaders, 'SUFFIX', 'Suffix')}
                                                </Grid>
                                                <Grid item xs={12} sm={4}>
                                                    {printValue(invoiceHeaders, 'PO_NUMBER', 'Po Number')}
                                                    {printValue(invoiceHeaders, 'PO_RELEASE', 'Po Release')}
                                                    {printValue(invoiceHeaders, 'PO_CODE', 'Po Code')}
                                                    {printValue(invoiceHeaders, 'PROC_LEVEL', 'Process Level')}
                                                </Grid>
                                                <Grid item xs={12} sm={4}>
                                                    {printValue(invoiceHeaders, 'VOUCHER_NBR', 'Voucher Number')}
                                                    {printValue(invoiceHeaders, 'DUE_DATE', 'Due Date')}
                                                    {printValue(invoiceHeaders, 'BASE_INV_AMT', 'Base Amount', '', '', 'currency')}
                                                    {printValue(invoiceHeaders, 'TRAN_INV_AMT', 'Tran Amount', '', '', 'currency')}
                                                    {printValue(invoiceHeaders, 'TRAN_TOT_PMT', 'Tran Payment', '', '', 'currency')}
                                                </Grid>
                                            </Grid>
                                        </Paper>
                                    </Grid>
                                </Grid>
                                <div>
                                    {invoiceDetails && (
                                        <Grid
                                            item
                                            xs={12}
                                            style={{ position: 'relative', marginTop: '1rem' }}
                                        >
                                            <ReactTable cols={invoiceMatchingReactTableCols} data={invoiceLineData} />
                                        </Grid>
                                    )}
                                </div>
                            </>
                        )}
                    </div>
                </div>

            </div>

        </>
    )
}
