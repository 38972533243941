import React from 'react'
import TableMui from './TableMui'
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Slide,
    TableFooter,
    TableCell,
    TableRow,
    AppBar,
    Toolbar,
    IconButton,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close'
import getTableOptions from '../constants/TableOptions'
import { Typography, Card, CardContent, Grid, Paper } from '@material-ui/core'
import { printValue } from '../utils/PrintValue'
import { currencyFormat } from '../utils/formatter'
import NumberFormat from 'react-number-format'
import ReactTable from '../components/ReactTable'
import LoadingCircle from '../components/common/LoadingCircle'

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction='up' ref={ref} {...props} />
})

const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD'
})

const useStyles = makeStyles((theme) => ({
    appBar: {
        position: 'relative',
        backgroundColor: (props) => props.primaryColor,
        height: '60px',
    },
}));

export default function Matching(props) {
    const classes = useStyles();

    const options = getTableOptions({
        cols: props.cols,
        fileName: props.fileName
    })
    options['rowsPerPage'] = 10000
    if (props.simpleTable) {
        options.download = false
        options.search = false
        options.filter = false
    }
    if (props.totalColIndex && props.tableData) {
        const tot = props.tableData.reduce((tot, cur) => {
            tot += cur[props.totalColIndex]
            return tot
        }, 0)

        options['customFooter'] = () => {
            return (
                <TableFooter>
                    <TableRow>
                        <TableCell
                            style={{ padding: '4px 12px' }}
                            colSpan={props.totalColIndex + 1}
                        >
                            <div style={{ float: 'right', color: 'black', fontSize: '14px' }}>
                                <span style={{ marginRight: '0.5rem' }}> Total: </span>
                                <span>{formatter.format(tot)}</span>
                            </div>
                        </TableCell>
                    </TableRow>
                </TableFooter>
            )
        }
    }

    if (props.noFooter) {
        options['customFooter'] = () => {
            return <TableFooter></TableFooter>
        }
    }

    const { headerData } = props
    return (
        <div>
            <Dialog
                open={props.isOpen}
                TransitionComponent={Transition}
                maxWidth='xl'
                fullWidth
                scroll="body"
                keepMounted
            >
                <AppBar className={classes.appBar}>
                    <Toolbar>
                        <IconButton
                            edge="start"
                            color="inherit"
                            onClick={props.handleClose}
                            aria-label="close"
                        >
                            <CloseIcon />
                        </IconButton>
                    </Toolbar>
                </AppBar>

                {props.isLoading ? (
                    <LoadingCircle />
                ) : (

                    <>
                        {!!props.title && <DialogTitle id='title'>{props.title}</DialogTitle>}
                        <DialogContent>

                            {!!props.contextText && (
                                <DialogContentText id='contentText'>
                                    {props.contextText}
                                </DialogContentText>
                            )}
                            {!!props.tableData && (
                                <>
                                    {props.reactTable ? (
                                        <ReactTable isLoading={props.isLoading ? props.isLoading : false} cols={props.cols} data={props.tableData} />
                                    ) : (
                                        <TableMui
                                            title={props.tableTitle ? props.tableTitle : ''}
                                            cols={props.cols}
                                            data={props.tableData}
                                            options={{ ...options }}
                                        />
                                    )}
                                </>
                            )}
                        </DialogContent>
                    </>
                )}
            </Dialog>
        </div>
    )
}
