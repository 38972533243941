import React from 'react'
import { dateFormat, currencyFormat } from '../utils/formatter';

export const printValue = (
  dataObj,
  lookup,
  description,
  secondary,
  secondType,
  type = ''
) => {
  if (type === 'currency') {
    return (
      <div>
        <span style={{ fontSize: '0.8rem', color: '#545454' }}>{`${description}:  `}</span>
        <span style={{ fontSize: '0.85rem' }}>
          {currencyFormat(dataObj[lookup])}
        </span>
      </div>
    )
  } else {
    const isDate = description.toLowerCase().indexOf('date') !== -1
    return (
      <div>
        <span style={{ fontSize: '0.8rem', color: '#545454' }}>{`${description}:  `}</span>
        <span style={{ fontSize: '0.85rem' }}>
          {`${isDate
              ? dateFormat(dataObj[lookup])
              : dataObj[lookup]
            }${secondary && dataObj[secondary]
              ? ` (${secondType !== 'date'
                ? dataObj[secondary]
                : dateFormat(dataObj[secondary])
              })`
              : ''
            } `}
        </span>
      </div>
    )
  }
}


export const printValueInline = (header, value) => {
  return (
    <span>
      <span style={{ fontSize: '0.8rem', color: '#545454' }}>{`${header}:  `}</span>
      <span style={{ fontSize: '0.85rem' }}>{`${value}      `}</span>
    </span>
  )
}