// import useRangeFilterDateColumn from './useRangeFilterDateColumn';
import { currencyFormat, dateFormat } from '../utils/formatter';
import useDateRangeCol from './useDateRangeCol';

const useInvoiceReactTableCols = () => {
  // const invoiceDateCol = useRangeFilterDateColumn(
  //   'INVOICE_DTE', // TODO
  //   'INVOICE_DTE'
  // ); // TODO Invoice Date

  const invoiceDateCol = useDateRangeCol(
    'INVOICE_DTE', // TODO
    'Invoice Date'
  ); // TODO Invoice Date

  const cols = [
    {
      accessorKey: 'COMPANY',
      header: 'Company',
      enableSorting: false,
      enableColumnFilter: true,
      size: 1,
    },
    {
      accessorKey: 'INVOICE',
      header: 'Invoice',
      enableSorting: false,
      enableColumnFilter: true,
      size: 1,
    },
    {
      accessorKey: 'INVOICE_TYPE',
      header: 'Type',
      enableSorting: false,
      enableColumnFilter: false,
      size: 1,
    },
    {
      accessorKey: 'VOUCHER_NBR',
      header: 'Voucher Number',
      enableSorting: false,
      enableColumnFilter: true,
      trimSearch: true,
      size: 1,
    },
    invoiceDateCol,
    // {
    //   accessorKey: 'INVOICE_DTE',
    //   header: 'Invoice Date',
    //   enableSorting: false,
    //   enableColumnFilter: false,
    //   size: 1,
    //   Cell: ({ cell }) => dateFormat(cell.getValue()),
    // },
    {
      accessorKey: 'BASE_INV_AMT',
      header: 'Amount',
      enableSorting: false,
      enableColumnFilter: false,
      size: 1,
      Cell: ({ cell }) => currencyFormat(cell.getValue()),
    },
    {
      accessorKey: 'TRAN_INV_AMT',
      header: 'Trans Amount',
      enableSorting: false,
      enableColumnFilter: false,
      size: 1,
      Cell: ({ cell }) => currencyFormat(cell.getValue()),
    },
    {
      accessorKey: 'DESCRIPTION',
      header: 'Description',
      enableSorting: false,
      enableColumnFilter: true,
      size: 1,
    },
    {
      accessorKey: 'DUE_DATE',
      header: 'Due Date',
      enableSorting: false,
      enableColumnFilter: false,
      size: 1,
      Cell: ({ cell }) => dateFormat(cell.getValue()),
    },
    // {
    //   accessorKey: 'PO_NUMBER',
    //   header: 'Po',
    //   enableSorting: false,
    //   enableColumnFilter: true,
    //   size: 1
    // },
    {
      accessorKey: 'PO_LINK',
      header: 'Po',
      enableSorting: false,
      enableColumnFilter: false,
      size: 1,
    },
    {
      accessorKey: 'Distribution',
      header: 'Distribution',
      enableSorting: false,
      enableColumnFilter: false,
      size: 1,
    },
    {
      accessorKey: 'Payment',
      header: 'Payment',
      enableSorting: false,
      enableColumnFilter: false,
      size: 1,
    },
    {
      accessorKey: 'OPERATOR',
      header: 'Operator',
      enableSorting: false,
      enableColumnFilter: false,
      size: 1,
    },
    //
    {
      accessorKey: 'PAY_RATE',
      header: 'Pay Rate',
      filterFn: 'contains',
      size: 1,
      Cell: ({ cell }) => currencyFormat(cell.getValue()),
    },
  ];

  return cols;
};



const usePoReactTableCols = () => {

  const poDateCol = useDateRangeCol(
    'PO_DATE', // TODO
    'Date'
  ); // TODO Invoice Date

  const cols = [
    {
      accessorKey: 'COMPANY',
      header: 'Company',
      enableSorting: false,
      enableColumnFilter: true,
      size: 1
    },
    {
      accessorKey: 'PO_NUMBER',
      header: 'PO Number',
      enableSorting: false,
      enableColumnFilter: true,
      trimSearch: true,
      Cell: ({ cell }) => {
        return <span>{cell.row.original.PO_LINK}</span>
      },
      size: 1
    },
    {
      accessorKey: 'PO_CODE',
      header: 'PO Code',
      enableSorting: false,
      enableColumnFilter: true,
      size: 1
    },
    poDateCol,
    // {
    //   accessorKey: 'PO_DATE',
    //   header: 'Date',
    //   enableSorting: false,
    //   enableColumnFilter: false,
    //   size: 1,
    //   Cell: ({ cell }) => dateFormat(cell.getValue()),
    // },
    {
      accessorKey: 'BUYER_CODE',
      header: 'Buyer',
      enableSorting: false,
      enableColumnFilter: true,
      size: 1
    },
    {
      accessorKey: 'TOT_PRD_AMT',
      header: 'Amount',
      enableSorting: false,
      enableColumnFilter: false,
      size: 1,
      Cell: ({ cell }) => currencyFormat(cell.getValue())
    },
    {
      accessorKey: 'NBR_LINES',
      header: 'Line Count',
      enableSorting: false,
      enableColumnFilter: false,
      size: 1
    },
    // {
    //   accessorKey: 'view',
    //   header: 'Payment',
    //   enableSorting: false,
    //   enableColumnFilter: false,
    //   size: 1
    // }
  ]

  return cols;
};


const useLocationReactTableCols = () => {

  const LocDateCol = useDateRangeCol(
    'EFFECTIVE_DATE', // TODO
    'Date'
  ); // TODO Invoice Date

  const cols = [
    {
      accessorKey: 'LOCATION_CODE',
      header: 'Location Code',
      filterFn: 'contains',
      size: 1
    },
    {
      accessorKey: 'LOC_TYPE',
      header: 'Type',
      filterFn: 'contains',
      size: 1
    },
    {
      accessorKey: 'ACTIVE_STATUS',
      header: 'Status',
      filterFn: 'contains',
      size: 1
    },
    LocDateCol,
    {
      accessorKey: 'ADDR1',
      header: 'Address 1',
      filterFn: 'contains',
      size: 1
    },
    {
      accessorKey: 'ADDR2',
      header: 'Address 2',
      filterFn: 'contains',
      size: 1
    },
    {
      accessorKey: 'ADDR3',
      header: 'Address 3',
      filterFn: 'contains',
      size: 1
    },
    {
      accessorKey: 'ADDR4',
      header: 'Address 4',
      filterFn: 'contains',
      size: 1
    },
    {
      accessorKey: 'CITY_ADDR5',
      header: 'City',
      filterFn: 'contains',
      size: 1
    },
    {
      accessorKey: 'STATE_PROV',
      header: 'State',
      filterFn: 'contains',
      size: 1
    },
    {
      accessorKey: 'POSTAL_CODE',
      header: 'Zip',
      filterFn: 'contains',
      size: 1
    },
    {
      accessorKey: 'COUNTY',
      header: 'County',
      filterFn: 'contains',
      size: 1
    },
    {
      accessorKey: 'COUNTRY',
      header: 'Country',
      filterFn: 'contains',
      size: 1
    },
    {
      accessorKey: 'CUR_ADDR',
      header: 'Current',
      filterFn: 'contains',
      size: 1
    }
  ]

  return cols;
};



const useInvoiceMatchingTableCols = () => {

  const cols = [
  {
    accessorKey: 'LINE_NBR',
    header: 'Line',
    enableSorting: false,
    enableColumnFilter: false,
    size:1
  },
  {
    accessorKey: 'ITEM',
    header: 'Item',
    enableSorting: false,
    enableColumnFilter: false,
    size:1
  },
  {
    accessorKey: 'ITEM_DESC',
    header: 'Desc',
    enableSorting: false,
    enableColumnFilter: false,
    size:1
  },
  {
    accessorKey: 'ENTERED_QTY',
    header: 'Qty',
    enableSorting: false,
    enableColumnFilter: false,
    size:1,
    Cell: ({ cell }) => {
      return <span>{cell.row.original.MATCH_LINK}</span>
    }
  },
  {
    accessorKey: 'ENT_UOM',
    header: 'UOM',
    enableSorting: false,
    enableColumnFilter: false,
    size:1
  },
  {
    accessorKey: 'ENT_UNIT_CST',
    header: 'Unit Cost',
    enableSorting: false,
    enableColumnFilter: false,
    size:1
  },
  {
    accessorKey: 'TOT_BASE_AMT',
    header: 'Amount',
    enableSorting: false,
    enableColumnFilter: false,
    size:1
  },
  {
    accessorKey: 'MATCHED_QTY',
    header: 'Match Qty',
    enableSorting: false,
    enableColumnFilter: false,
    size:1
  }
]
  return cols;
};


const useInvoiceMatchReactTableCols = () => {
const cols = [
{
accessorKey: 'DOC',
header: 'DOC',
enableSorting: false,
enableColumnFilter: false,
size:1
},
    {
      accessorKey: 'DESCRIPTION',
      header: 'DESCRIPTION',
      enableSorting: false,
      enableColumnFilter: false,
      size:1
      },
    {
      accessorKey: 'DOC_NUMBER',
      header: 'DOC_NUMBER',
      enableSorting: false,
      enableColumnFilter: false,
      size:1
      },
    {
      accessorKey: 'STATUS',
      header: 'STATUS',
      enableSorting: false,
      enableColumnFilter: false,
      size:1
      },
    {
      accessorKey: 'ORIG_REC_QTY',
      header: 'ORIG_REC_QTY',
      enableSorting: false,
      enableColumnFilter: false,
      size:1
      },
    {
      accessorKey: 'MATCHED_QTY',
      header: 'MATCHED_QTY',
      enableSorting: false,
      enableColumnFilter: false,
      size:1
      },
    {
      accessorKey: 'QTY_TO_MATCH',
      header: 'QTY_TO_MATCH',
      enableSorting: false,
      enableColumnFilter: false,
      size:1
      },
    {
      accessorKey: 'ENT_REC_UOM',
      header: 'ENT_REC_UOM',
      enableSorting: false,
      enableColumnFilter: false,
      size:1
      },
    {
      accessorKey: 'ORIG_UNIT_CST',
      header: 'ORIG_UNIT_CST',
      enableSorting: false,
      enableColumnFilter: false,
      size:1,
      Cell: ({ cell }) => currencyFormat(cell.getValue())
      },
    {
      accessorKey: 'MATCH_UNIT_CST',
      header: 'MATCH_UNIT_CST',
      enableSorting: false,
      enableColumnFilter: false,
      size:1,
      Cell: ({ cell }) => currencyFormat(cell.getValue())
      },
      {
        accessorKey: 'EXTENDED_AMT',
        header: 'EXTENDED_AMT',
        enableSorting: false,
        enableColumnFilter: false,
        size:1,
        Cell: ({ cell }) => currencyFormat(cell.getValue())
        }
      ]
return cols
    }

export { 
  useInvoiceReactTableCols,
  usePoReactTableCols,
  useLocationReactTableCols,
  useInvoiceMatchingTableCols,
  useInvoiceMatchReactTableCols };
