import { currencyFormat } from '../utils/formatter';
import useDateRangeCol from './useDateRangeCol';

const usePoDetailReactTableCols = () => {
  const earlyDlDateCol = useDateRangeCol('EARLY_DL_DATE', 'Delivery date');

  const poDetailReactTableCols = [
    {
      accessorKey: 'LINE_NBR',
      header: '#',
      filterFn: 'contains',
    },
    {
      accessorKey: 'ITEM_TYPE',
      header: 'Type',
      filterFn: 'contains',
    },
    {
      accessorKey: 'DESCRIPTION',
      header: 'Item',
      filterFn: 'contains',
    },
    {
      accessorKey: 'MATCH_DTL_KEY',
      header: 'Description',
      filterFn: 'contains',
    },
    {
      accessorKey: 'QUANTITY',
      header: 'Qty',
      filterFn: 'contains',
    },
    {
      accessorKey: 'ENT_BUY_UOM',
      header: 'UOM',
      filterFn: 'contains',
    },
    {
      accessorKey: 'ENT_UNIT_CST',
      header: 'Unit Cost',
      filterFn: 'contains',
      Cell: ({ cell }) => currencyFormat(cell.getValue()),
    },
    earlyDlDateCol,
    {
      accessorKey: 'REC_QTY',
      header: 'Rec Qty',
      filterFn: 'contains',
    },
    {
      accessorKey: 'MATCHED_QTY',
      header: 'Match Qty',
      filterFn: 'contains',
      Cell: ({ cell }) => {
        return <span>{cell.row.original.MATCH_LINK}</span>
      }
    },
    {
      accessorKey: 'LOCATION',
      header: 'Loc',
      filterFn: 'contains',
    },
    {
      accessorKey: 'CLOSED_FL',
      header: 'Closed',
      filterFn: 'contains',
    },
    {
      accessorKey: 'EXTENDED_AMT',
      header: 'Ext Amt',
      filterFn: 'contains',
      Cell: ({ cell }) => currencyFormat(cell.getValue()),
    },
    {
      accessorKey: 'VEN_ITEM',
      header: 'Ven Item',
      filterFn: 'contains',
    },
    {
      accessorKey: 'SOURCE',
      header: 'Source',
      filterFn: 'contains',
    },
    {
      accessorKey: 'COMMENT_LINK',
      header: 'Comment',
      filterFn: 'contains',
    },
  ];

  return poDetailReactTableCols;
};

const usePoReceiveReactTableCols = () => {
  const recDateCol = useDateRangeCol('REC_DATE', 'Date');

  const poReceiveReactTableCols = [
    {
      accessorKey: 'REC_LINE_VIEW',
      header: 'Receiver Num',
      filterFn: 'contains',
    },
    {
      accessorKey: 'COMPANY',
      header: 'Company',
      filterFn: 'contains',
    },
    {
      accessorKey: 'OPER_ID',
      header: 'Operator',
      filterFn: 'contains',
    },
    {
      accessorKey: 'BUYER',
      header: 'Buyer',
      filterFn: 'contains',
    },
    recDateCol,
    {
      accessorKey: 'STATUS',
      header: 'Status',
      filterFn: 'contains',
    },
    {
      accessorKey: 'LOGGED_FLAG',
      header: 'Logged',
      filterFn: 'contains',
    },
    {
      accessorKey: 'REPRINT_REC_FL',
      header: 'Reprint',
      filterFn: 'contains',
    },
    {
      accessorKey: 'ACTUAL_TIME',
      header: 'Time',
      filterFn: 'contains',
    },
    {
      accessorKey: 'ERS_STATUS',
      header: 'Ers Status',
      filterFn: 'contains',
    },
    {
      accessorKey: 'PRINT_CODE',
      header: 'Print Code',
      filterFn: 'contains',
    },
    {
      accessorKey: 'MATCH_AMT',
      header: 'Match Amt',
      filterFn: 'contains',
      Cell: ({ cell }) => currencyFormat(cell.getValue()),
    },
    {
      accessorKey: 'MATCH_AMT_TOT',
      header: 'Total',
      filterFn: 'contains',
      Cell: ({ cell }) => currencyFormat(cell.getValue()),
    },
  ];

  return poReceiveReactTableCols;
};

const usePoCommentReactTableCols = () => {

  const poCommentReactTableCols = [
    {
      accessorKey: 'COMMENT_NAME',
      header: 'Comment',
      filterFn: 'contains',
    },
    {
      accessorKey: 'COMMENT_VALUE',
      header: 'Value',
      filterFn: 'contains',
    }
  ];

  return poCommentReactTableCols;
}

const usePoMatchingReactTableCols = () => {
  const poMatchingReactTableCols = [
    {
      accessorKey: 'DOC',
      header: 'DOC',
      filterFn: 'contains'
    },
    {
      accessorKey: 'DESCRIPTION',
      header: 'DESCRIPTION',
      filterFn: 'contains'
    },
    {
      accessorKey: 'DOC_NUMBER',
      header: 'DOC_NUMBER',
      filterFn: 'contains'
    },
    {
      accessorKey: 'STATUS',
      header: 'STATUS',
      filterFn: 'contains'
    },
    {
      accessorKey: 'ORIG_REC_QTY',
      header: 'ORIG_REC_QTY',
      filterFn: 'contains'
    },
    {
      accessorKey: 'MATCHED_QTY',
      header: 'MATCHED_QTY',
      filterFn: 'contains'
    },
    {
      accessorKey: 'QTY_TO_MATCH',
      header: 'QTY_TO_MATCH',
      filterFn: 'contains'
    },
    {
      accessorKey: 'ENT_REC_UOM',
      header: 'ENT_REC_UOM',
      filterFn: 'contains'
    },
    {
      accessorKey: 'ORIG_UNIT_CST',
      header: 'ORIG_UNIT_CST',
      filterFn: 'contains'
    },
    {
      accessorKey: 'MATCH_UNIT_CST',
      header: 'MATCH_UNIT_CST',
      filterFn: 'contains'
    },
    {
      accessorKey: 'EXTENDED_AMT',
      header: 'Amt',
      filterFn: 'contains'
    }
  ];

  return poMatchingReactTableCols;
}

export { usePoDetailReactTableCols, usePoReceiveReactTableCols, usePoCommentReactTableCols,usePoMatchingReactTableCols };
