import React from 'react'
import { Tabs, Tab } from '@material-ui/core'
import { makeStyles, withStyles } from '@material-ui/core/styles'

const useStyles = makeStyles({
  tabsRoot: {
    marginTop: '1rem',
    borderBottom: '1px solid #e8e8e8'
  },
  tabsIndicator: {
    backgroundColor: props => props.color
  },
  tabRoot: {
    flexGrow: 1,
    border: '2px solid #e8e8e8',
    borderBottomWidth: 0,
    borderRadius: '5px 5px 0 0',
    textTransform: 'none',
    minWidth: 72,
    maxWidth: 150,
    fontSize: '14px',
    fontWeight: 500,
    marginRight: '8px',
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"'
    ].join(','),
    '&:hover': {
      color: props => props.color,
      opacity: 0.85
    },
    '&$selected': {
      color: props => props.color
      // fontWeight: 500,
    },
    '&:focus': {
      color: props => props.color
    }
  },
  tabSelected: {
    color: props => props.color
  },
  selected: {
    color: props => props.color
  },
  root: {
    flexGrow: 1
  },
  padding: {
    padding: '1rem'
  },
  demo2: {
    backgroundColor: '#3F51B6'
  }
})

export const AntTabs = props => {
  const { tabsRoot, tabsIndicator, tabRoot, tabSelected } = useStyles(props)
  const { handleChange, value, tabs, controlledTabs} = props

  if( controlledTabs ) {
    return (
      <div>
        <div>
          <Tabs
            classes={{ root: tabsRoot, indicator: tabsIndicator }}
            indicatorColor='primary'
            textColor='primary'
            value={value}
            variant='scrollable'
            scrollButtons='auto'
            onChange={handleChange}
            aria-label='ant example'
          >
            {tabs.map((t, index) => (
              <Tab
                key={index}
                classes={{ root: tabRoot, selected: tabSelected }}
                color={props.color}
                value={t.value}
                label={t.display}
              />
            ))}
          </Tabs>
        </div>
      </div>
    )
  } else {
  return (
    <div>
      <div>
        <Tabs
          classes={{ root: tabsRoot, indicator: tabsIndicator }}
          indicatorColor='primary'
          textColor='primary'
          value={value}
          variant='scrollable'
          scrollButtons='auto'
          onChange={handleChange}
          aria-label='ant example'
        >
          {tabs.map((t, index) => (
            <Tab
              classes={{ root: tabRoot, selected: tabSelected }}
              color={props.color}
              key={index}
              label={t}
            />
          ))}
        </Tabs>
      </div>
    </div>
  )
}
}
