import { currencyFormat } from '../utils/formatter';
import useDateRangeCol from './useDateRangeCol';

const usePayRateHistCols = () => {
  const beginDate = useDateRangeCol('BEG_DATE', 'Begin Date');
  const dateStampDate = useDateRangeCol('DATE_STAMP', 'Date');

  const payRateHistCols = [
    beginDate,
    {
      accessorKey: 'PAY_RATE',
      header: 'Pay Rate',
      filterFn: 'contains',
      size: 1,
      Cell: ({ cell }) => currencyFormat(cell.getValue()),
    },
    {
      accessorKey: 'ANNUAL_SALARY',
      header: 'Salary',
      filterFn: 'contains',
      size: 1,
      Cell: ({ cell }) => currencyFormat(cell.getValue()),
    },
    dateStampDate,
    {
      accessorKey: 'SALARY_CLASS',
      header: 'Salary Class',
      filterFn: 'contains',
      size: 1,
    },
    {
      accessorKey: 'POS_LEVEL',
      header: 'Position Level',
      filterFn: 'contains',
      size: 1,
    },
    {
      accessorKey: 'ANNUAL_HOURS',
      header: 'Annual Hours',
      filterFn: 'contains',
      size: 1,
    },
    {
      accessorKey: 'NBR_FTE',
      header: 'NBR FTE',
      filterFn: 'contains',
      size: 1,
    },
  ];

  return payRateHistCols;
};

const usePerActHistCols = () => {
  const effectDateCol = useDateRangeCol('EFFECT_DATE', 'Effect Date');
  const dateStampCol = useDateRangeCol('DATE_STAMP', 'Date stamp');

  const perActHistCols = [
    {
      accessorKey: 'ACTION_CODE',
      header: 'Action Code',
      filterFn: 'contains',
      size: 1,
    },
    effectDateCol,
    {
      accessorKey: 'ACTION_NBR',
      header: 'Action Number',
      filterFn: 'contains',
      size: 1,
    },
    {
      accessorKey: 'REASON_01',
      header: 'Reason',
      filterFn: 'contains',
      size: 1,
    },
    {
      accessorKey: 'REASON_02',
      header: 'Reason 2',
      filterFn: 'contains',
      size: 1,
    },
    {
      accessorKey: 'USER_ID',
      header: 'User ID',
      filterFn: 'contains',
      size: 1,
    },
    {
      accessorKey: 'ACTION_TYPE',
      header: 'Action Type',
      filterFn: 'contains',
      size: 1,
    },
    {
      accessorKey: 'CREATE_USER',
      header: 'Create User',
      filterFn: 'contains',
      size: 1,
    },
    dateStampCol,
    {
      accessorKey: 'VIEW',
      header: 'View',
      enableSorting: false,
      enableColumnFilter: false,
      size: 1,
      dontDownload: true,
    },
  ];
  return perActHistCols;
};

const useReviewCols = () => {
  const reviewDateCol = useDateRangeCol('REVIEW_DATE', 'Review Date');
  const scheduleDateCol = useDateRangeCol('SCHED_DATE', 'Schedule Date');

  const reviewCols = [
    {
      accessorKey: 'REVIEW_TYPE',
      header: 'Review Type',
      filterFn: 'contains',
      size: 1,
    },
    {
      accessorKey: 'RATING',
      header: 'Rating',
      filterFn: 'contains',
      size: 1,
    },
    reviewDateCol,
    scheduleDateCol,
    {
      accessorKey: 'REVIEWED_BY',
      header: 'Reviewed By',
      filterFn: 'contains',
      size: 1,
    },
  ];
  return reviewCols;
};

const useCertificatesCols = () => {
  const dateAcquiredCol = useDateRangeCol('DATE_ACQUIRED', 'Date Acquired');
  const renewDateCol = useDateRangeCol('RENEW_DATE', 'Renew Date');

  const certificatesCols = [
    {
      accessorKey: 'TYPE',
      header: 'Type',
      filterFn: 'contains',
      size: 1,
    },
    {
      accessorKey: 'CODE',
      header: 'Code',
      filterFn: 'contains',
      size: 1,
    },
    {
      accessorKey: 'LIC_NUMBER',
      header: 'License Number',
      filterFn: 'contains',
      size: 1,
    },
    {
      accessorKey: 'INSTRUCTOR',
      header: 'Institution',
      filterFn: 'contains',
      size: 1,
    },
    dateAcquiredCol,
    renewDateCol,
    {
      accessorKey: 'IN_PRO_FLAG',
      header: 'In Process',
      filterFn: 'contains',
      size: 1,
    },
    {
      accessorKey: 'VERIFIED_FLAG',
      header: 'Verify',
      filterFn: 'contains',
      size: 1,
    },
  ];

  return certificatesCols;
};


const useDisciplinaryCols = () => {
  const dateActionCol = useDateRangeCol('DATE', 'Action Date');
  const dateOutcomeCol = useDateRangeCol('OUTCOME_DATE', 'Outcome Date');
  const dateReportCol = useDateRangeCol('REPORT_DATE', 'Report Date');


  const disciplinaryCols = [
    {
      accessorKey: 'TYPE',
      header: 'Type',
      filterFn: 'contains',
      size: 1,
    },
    {
      accessorKey: 'DESCRIPTION',
      header: 'Desc',
      filterFn: 'contains',
      size: 1,
    },
    dateActionCol,
    dateReportCol,
    {
      accessorKey: 'GRIEV_DIS_NBR',
      header: 'Status',
      filterFn: 'contains',
      size: 1,
    },
    {
      accessorKey: 'STATUS_DESC',
      header: 'Description',
      filterFn: 'contains',
      size: 1,
    },
    {
      accessorKey: 'DEPARTMENT',
      header: 'Dept',
      filterFn: 'contains',
      size: 1,
    },
    {
      accessorKey: 'JOB_CODE',
      header: 'Job Code',
      filterFn: 'contains',
      size: 1,
    },
    {
      accessorKey: 'REPORT_BY_NAME',
      header: 'Report',
      filterFn: 'contains',
      size: 1,
    },
    {
      accessorKey: 'SUPERVISOR',
      header: 'Supervisor',
      filterFn: 'contains',
      size: 1,
    },
    {
      accessorKey: 'COMMENT',
      header: 'Comment',
      filterFn: 'contains',
      size: 1,
    },
    dateOutcomeCol,
    {
      accessorKey: 'OUTCOME',
      header: 'Outcome',
      filterFn: 'contains',
      size: 1,
    }
  ];

  return disciplinaryCols;
};



const useDedCols = () => {
  const beginDateCol = useDateRangeCol('Begin_Date', 'Begin date');
  const endDateCol = useDateRangeCol('End_Date', 'End date');

  const dedCols = [
    {
      accessorKey: 'Ded',
      header: 'Ded',
      filterFn: 'contains',
      size: 1,
    },
    {
      accessorKey: 'Description',
      header: 'Description',
      filterFn: 'contains',
      size: 1,
    },
    {
      accessorKey: 'Amount',
      header: 'Amount or Percent',
      filterFn: 'contains',
    },
    {
      accessorKey: 'Type',
      header: 'Type',
      filterFn: 'contains',
      size: 1,
    },
    {
      accessorKey: 'Balance',
      header: 'Balance Amount',
      filterFn: 'contains',
      size: 1,
      Cell: ({ cell }) => currencyFormat(cell.getValue()),
    },
    {
      accessorKey: 'one',
      header: '1',
      filterFn: 'contains',
      size: 1,
    },
    {
      accessorKey: 'two',
      header: '2',
      filterFn: 'contains',
      size: 1,
    },
    {
      accessorKey: 'three',
      header: '3',
      filterFn: 'contains',
      size: 1,
    },
    {
      accessorKey: 'four',
      header: '4',
      filterFn: 'contains',
      size: 1,
    },
    {
      accessorKey: 'five',
      header: '5',
      filterFn: 'contains',
      size: 1,
    },
    {
      accessorKey: 'six',
      header: '6',
      filterFn: 'contains',
      size: 1,
    },
    {
      accessorKey: 'seven',
      header: '7',
      filterFn: 'contains',
      size: 1,
    },
    {
      accessorKey: 'eight',
      header: '8',
      filterFn: 'contains',
      size: 1,
    },
    {
      accessorKey: 'nine',
      header: '9',
      filterFn: 'contains',
      size: 1,
    },
    beginDateCol,
    endDateCol,
    {
      accessorKey: 'Pty',
      header: 'Pty',
      filterFn: 'contains',
      size: 1,
    },
    {
      accessorKey: 'Ar',
      header: 'Ar',
      filterFn: 'contains',
      size: 1,
    },
  ];

  return dedCols;
};

const useCheckCols = () => {
  const checkDateCol = useDateRangeCol('CHECK_DATE', 'Check date');
  const perEndDateCol = useDateRangeCol('PER_END_DATE', 'Period end date');

  const checkCols = [
    {
      accessorKey: 'CHECK_VIEW',
      header: 'Check Id',
      filterFn: 'contains',
      size: 1,
    },
    {
      accessorKey: 'CHECK_NBR',
      header: 'Check Number',
      filterFn: 'contains',
      size: 1,
    },
    checkDateCol,
    {
      accessorKey: 'PRINT_TYPE',
      header: 'Print Type',
      filterFn: 'contains',
      size: 1,
    },
    {
      accessorKey: 'CHECK_TYPE',
      header: 'Check Type',
      filterFn: 'contains',
      size: 1,
    },
    {
      accessorKey: 'STATUS',
      header: 'Status',
      filterFn: 'contains',
      size: 1,
    },
    {
      accessorKey: 'GROSS_PAY',
      header: 'Gros Pay',
      filterFn: 'contains',
      size: 1,
      Cell: ({ cell }) => currencyFormat(cell.getValue()),
    },
    {
      accessorKey: 'NET_PAY_AMT',
      header: 'Net Pay Amount',
      filterFn: 'contains',
      size: 1,
      Cell: ({ cell }) => currencyFormat(cell.getValue()),
    },
    perEndDateCol,
    {
      accessorKey: 'M_CHECK_TYPE',
      header: 'M Check Type',
      filterFn: 'contains',
      size: 1,
    },
  ];

  return checkCols;
};

const useHrHistCols = () => {
  const beginDateCol = useDateRangeCol('BEG_DATE', 'Begin date');
  const dateStampCol = useDateRangeCol('DATE_STAMP', 'Date');
  const effectDateCol = useDateRangeCol('EFFECT_DATE', 'Effective date');

  const hrHistCols = [
    {
      accessorKey: 'FLD_NBR',
      header: 'Folder Number',
      filterFn: 'contains',
      size: 1,
    },
    beginDateCol,
    {
      accessorKey: 'N_VALUE',
      header: 'N Value',
      filterFn: 'contains',
      size: 1,
    },
    {
      accessorKey: 'A_VALUE',
      header: 'A Value',
      filterFn: 'contains',
      size: 1,
    },
    {
      accessorKey: 'D_VALUE',
      header: 'D Value',
      filterFn: 'contains',
      size: 1,
    },
    {
      accessorKey: 'USER_ID',
      header: 'User ID',
      filterFn: 'contains',
      size: 1,
    },
    dateStampCol,
    {
      accessorKey: 'ACTION_CODE',
      header: 'Action Code',
      filterFn: 'contains',
      size: 1,
    },
    effectDateCol,
    {
      accessorKey: 'REASON_01',
      header: 'Reason 1',
      filterFn: 'contains',
      size: 1,
    },
    {
      accessorKey: 'REASON_02',
      header: 'Reason 2',
      filterFn: 'contains',
      size: 1,
    },
    {
      accessorKey: 'VIEW',
      header: 'View',
      filterFn: 'contains',
      size: 1,
    },
  ];
  return hrHistCols;
};

const useBenefitCols = () => {
  const startDateCol = useDateRangeCol('START_DATE', 'Start date');
  const stopDateCol = useDateRangeCol('STOP_DATE', 'Stop date');

  const benefitCols = [
    {
      accessorKey: 'PLAN_TYPE',
      header: 'Plan Type',
      filterFn: 'contains',
      size: 1,
    },
    {
      accessorKey: 'PLAN_CODE',
      header: 'Plan Code',
      filterFn: 'contains',
      size: 1,
    },
    {
      accessorKey: 'DISPLAY_DESC',
      header: 'Description',
      filterFn: 'contains',
      size: 1,
    },
    {
      accessorKey: 'FLEX_PLAN',
      header: 'Flex Plan',
      filterFn: 'contains',
      size: 1,
    },
    startDateCol,
    stopDateCol,
    {
      accessorKey: 'PLAN_OPTION',
      header: 'Plan Option',
      filterFn: 'contains',
      size: 1,
    },
    {
      accessorKey: 'COV_OPTION',
      header: 'Cov Option',
      filterFn: 'contains',
      size: 1,
    },
    {
      accessorKey: 'COVER_AMT',
      header: 'Cover Amount',
      filterFn: 'contains',
      size: 1,
      Cell: ({ cell }) => currencyFormat(cell.getValue()),
    },
    {
      accessorKey: 'DEP_COVER_AMT',
      header: 'Dep Cover Amount',
      filterFn: 'contains',
      size: 1,
      Cell: ({ cell }) => currencyFormat(cell.getValue()),
    },
    {
      accessorKey: 'ANNUAL_AMT',
      header: 'Annual Amount',
      filterFn: 'contains',
      size: 1,
      Cell: ({ cell }) => currencyFormat(cell.getValue()),
    },
  ];

  return benefitCols;
};

const useDependentCols = () => {
  const birthDateCol = useDateRangeCol('birthdate', 'Birth date');
  const deathDateCol = useDateRangeCol('death_date', 'Death date');

  const dependentCols = [
    {
      accessorKey: 'rel_code',
      header: 'Relation Code',
      filterFn: 'contains',
      size: 1,
    },
    {
      accessorKey: 'last_name',
      header: 'Last Name',
      filterFn: 'contains',
      size: 1,
    },
    {
      accessorKey: 'first_name',
      header: 'First Name',
      filterFn: 'contains',
      size: 1,
    },
    {
      accessorKey: 'fica_nbr',
      header: 'Fica',
      filterFn: 'contains',
      size: 1,
    },
    birthDateCol,
    {
      accessorKey: 'sex',
      header: 'Sex',
      filterFn: 'contains',
      size: 1,
    },
    {
      accessorKey: 'dep_type',
      header: 'Type',
      filterFn: 'contains',
      size: 1,
    },
    {
      accessorKey: 'active_flag',
      header: 'Active',
      filterFn: 'contains',
      size: 1,
    },
    deathDateCol,
    {
      accessorKey: 'VIEW',
      header: 'Benefits',
      filterFn: 'contains',
      size: 1,
    },
  ];

  return dependentCols;
};

const useTaxCols = () => {
  const taxCols = [
    {
      accessorKey: 'DED_CODE',
      header: 'Ded',
      filterFn: 'contains',
      size: 1,
    },
    {
      accessorKey: 'DESCRIPTION',
      header: 'Description',
      filterFn: 'contains',
      size: 1,
    },
    {
      accessorKey: 'RES_CODE',
      header: 'Res',
      filterFn: 'contains',
      size: 1,
    },
    {
      accessorKey: 'MARITAL_STATUS',
      header: 'Mar Status',
      filterFn: 'contains',
      size: 1,
    },
    {
      accessorKey: 'EXEMPTIONS',
      header: 'Exempt Number',
      filterFn: 'contains',
      size: 1,
    },
    {
      accessorKey: 'EXEMPT_AMOUNT',
      header: 'Exempt Amount',
      filterFn: 'contains',
      size: 1,
    },
    {
      accessorKey: 'TAX_EXEMPT_FLG',
      header: 'Tax Exm',
      filterFn: 'contains',
      size: 1,
    },
    {
      accessorKey: 'CERT_CODE',
      header: 'Cert Code',
      filterFn: 'contains',
      size: 1,
    },
  ];

  return taxCols;
};


export {
  usePayRateHistCols,
  usePerActHistCols,
  useReviewCols,
  useCertificatesCols,
  useDedCols,
  useCheckCols,
  useHrHistCols,
  useBenefitCols,
  useDependentCols,
  useDisciplinaryCols,
  useTaxCols
};
