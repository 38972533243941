import React, { useState, useEffect } from 'react'
import {
  Grid,
  TextField,
  IconButton,
  Tooltip,
  Button,
  Typography,
  Paper,
  Box,
  Container
} from '@material-ui/core'
import LambdaFetch from '../functions/FetchFromLambda'
import LoadingButton from '../components/LoadingButton'
import { ContentCopy } from '@mui/icons-material'
import toast from '../components/Toast'

export default function UserAccount (props) {
  const [state, setstate] = useState({
    isLoading: false
  })
  const [credentials, setCredentials] = useState({
    host: '',
    port: '',
    username: '',
    password: ''
  })
  const [copiedField, setCopiedField] = useState('')
  const fetchData = async () => {
    try {

        setstate({...state, isLoading: true})
        const resp = await LambdaFetch(
          'user-account',
          'post',
          props.fetchInitialData.credentials.user.accessToken,
          JSON.stringify({
            action: 'get-db-credentials'
          }),
          '',
          props.fetchInitialData.credentials
        )
        const {host,port, username, password} = JSON.parse(resp.data.secertString)
      setCredentials({
        host,
        port,
        username,
        password
      })
      setstate({...state, isLoading: false})

    } catch (e) {
      console.log(e)
      toast.persist(e)
      setstate({...state, isLoading: false})

    }
  }
  const handleCopy = (text, field) => {
    navigator.clipboard.writeText(text)
    setCopiedField(field)
    setTimeout(() => setCopiedField(''), 2000) // Clear copied message after 2 seconds
  }
  return (
    <Container maxWidth='sm' style={{ marginTop: '2rem' }}>
      <Typography variant='h6' gutterBottom>
        Database Access Credentials
      </Typography>
      <LoadingButton
        label='Submit'
        isLoading={state.isLoading}
        color='primaryVLButton'
        fn={() => fetchData()}
      />
      {credentials.username && credentials.password && (
        <Paper elevation={3} style={{ marginTop: '1rem', padding: '1rem' }}>
          {Object.keys(credentials).map(cur => {
            return (
              <>
                <Box display='flex' alignItems='center' marginTop={1}>
                  <TextField
                    label={cur}
                    variant='outlined'
                    fullWidth
                    size='small'
                    value={credentials[cur]}
                    InputProps={{
                      readOnly: true
                    }}
                    style={{ marginRight: '0.5rem' }}
                  />
                  <Tooltip title='Copy Password'>
                    <IconButton
                      onClick={() =>
                        handleCopy(credentials[cur], cur)
                      }
                      aria-label={`copy ${cur}`}
                      color='primary'
                    >
                      <ContentCopy />
                    </IconButton>
                  </Tooltip>
                </Box>
                {copiedField === cur && (
                  <Typography
                    variant='body2'
                    color='success.main'
                    style={{ marginTop: '0.5rem' }}
                  >
                    {`${cur} copied to clipboard!`}
                  </Typography>
                )}
              </>
            )
          })}
        </Paper>
      )}
    </Container>
  )
}


