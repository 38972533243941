import React, { useState } from 'react'
import {
  IconButton,
  Slide,
  Dialog,
  DialogContent,
  AppBar,
  Toolbar
} from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import TableMui from './TableMui'
import getTableOptions from '../constants/TableOptions'
import { poCols, poReactTableCols } from '../constants/VendorCols'
import PoDetail from './PoDetail'
import LambdaFetch from '../functions/FetchFromLambda'
import { makeStyles } from '@material-ui/core/styles'
import { smallScreenWidth } from '../constants/AppConstants'
import PoDetailDialog from './PoDetailDialog'
import { ServerSideTable } from './ServerSideTable'
import ReactTableServerSide from '../components/ReactTableServerSide'
import { usePoReactTableCols } from '../hooks/VendorCols'
import { DashboardAthenaTableServerSide } from '../components/DashboardAthenaTableServerSide'
import fetchFromAthena from '../functions/FetchFromAthena'
import { DashboardAthenaTableServerSideNoSelect } from '../components/DashboardAthenaTableServerSideNoSelect'

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction='up' ref={ref} {...props} />
})

const useStyles = makeStyles(theme => ({
  appBar: {
    position: 'relative',
    backgroundColor: props => props.primaryColor,
    height: '60px'
  }
}))

export function VendorPosServerSide(props) {
  const { credentials } = props.fetchInitialData
  const classes = useStyles({ primaryColor: credentials.primaryAppColor })
  const [headers, setHeaders] = React.useState({
    company: null,
    poNum: null,
    code: null
  })

  const tableDataTransformation = rows => {
    return rows.map(row => {
      return {
        ...row,
        PO_LINK:
          row.PO_NUMBER && row.COMPANY ? (
            <div
              className='editLink'
              onClick={() => {
                const win = window.open(
                  `/po?company=${row.COMPANY}&po=${row.PO_NUMBER}&pocode=${row.PO_CODE}`,
                  '_blank'
                )
                win.focus()
              }}
            >
              {row.PO_NUMBER}
            </div>
          ) : (
            ''
          )
      }
    })
  }

  const poServerSideCols = usePoReactTableCols();

  return (
    <>
      <div style={{ marginTop: '1rem ' }}>
        <DashboardAthenaTableServerSideNoSelect
          {...props}
          params={{ vendor: props.vendor, vendorGroup: props.selectedGroup }}
          tableColumns={poServerSideCols}
          reportParamName="po_by_vendor_read_serverside"
          reportName='po_by_vendor_read_serverside_temp'
          tableTitle={''}
          transform={tableDataTransformation}
          callback={() => { }}
          downloadFileName={'Po_Vendor_Download'}
        />
      </div>
    </>
  )
}
