import React from 'react'
import { Grid, Paper } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

import { dateFormat } from '../utils/formatter'

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1
  },
  paper: {
    padding: theme.spacing(2),
    color: 'rgba(0,0,0,0.9)',
    height: '100%'
  },
  descript: { fontSize: '0.8rem', color: '#545454' },
  descriptVal: { fontSize: '0.85rem' }
}))

export function VendorBank (props) {
  const classes = useStyles()

  const printValue = (dataObj, lookup, description, secondary, secondType) => {
    const isDate = description.toLowerCase().indexOf('date') !== -1

    return (
      <div>
        <span className={classes.descript}>{`${description}:  `}</span>
        <span className={classes.descriptVal}>
          {`${isDate ? dateFormat(dataObj[lookup]) : dataObj[lookup]}${
            secondary && dataObj[secondary]
              ? ` (${
                  secondType !== 'date'
                    ? dataObj[secondary]
                    : dateFormat(dataObj[secondary])
                })`
              : ''
          } `}
        </span>
      </div>
    )
  }

  const { bankInfo } = props
  if(!bankInfo) {
    return <div>No Data</div>
  }
  return (
    <>
      <div className={classes.root}>
        <Grid
          container
          spacing={3}
          direction='row'
          justifyContent='flex-start'
          alignItems='stretch'
        >
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <Paper elevation={3} className={classes.paper}>
              <Grid
                container
                spacing={2}
                direction='row'
                justifyContent='space-evenly'
                alignItems='flex-start'
              >
                <Grid item xs={12} sm={6} lg={6}>
                  {printValue(bankInfo, 'BANK_ENTITY', 'Vendor Bank Identification')}
                  {printValue(bankInfo, 'VBANK_ACCT_NO', 'Vendor Bank Account')}
                  {printValue(bankInfo, 'BANK_NAME', 'Bank Name')}
                  {printValue(bankInfo, 'BRANCH_NAME', 'Branch Name')}
                  {printValue(bankInfo, 'ADDR1', 'Address 1')}
                  {printValue(bankInfo, 'ADDR2', 'Address 2')}
                  {printValue(bankInfo, 'ADDR3', 'Address 3')}
                  {printValue(bankInfo, 'ADDR4', 'Address 4')}
                  {printValue(bankInfo, 'CITY_ADDR5', 'City')}
                  {printValue(bankInfo, 'STATE_PROV', 'State')}
                </Grid>
                <Grid item xs={12} sm={6} lg={6}>
                  {printValue(bankInfo, 'PHONE_PREFIX', 'Phone Prefix')}
                  {printValue(bankInfo, 'PHONE_NUMBER', 'Phone Number')}
                  {printValue(bankInfo, 'PHONE_EXT', 'Phone Ext')}
                  {printValue(bankInfo, 'FAX_PREFIX', 'Fax Prefix')}
                  {printValue(bankInfo, 'FAX_NUMBER', 'Fax Number')}
                  {printValue(bankInfo, 'FAX_EXT', 'Fax Ext')}
                </Grid>
              </Grid>
            </Paper>
          </Grid>
        </Grid>
      </div>
    </>
  )
}
