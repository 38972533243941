import React, { useState } from 'react';
import {
  Typography,
  Grid,
  Dialog,
  DialogContent,
  DialogActions,
  Button,
  Slide,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import moment from 'moment-timezone';
import { icTransCols } from '../constants/ItemCols';
import getTableOptions from '../constants/TableOptions';
import TableMui from './TableMui';
import { dateFormat } from '../utils/formatter';
import ReactTable from '../components/ReactTable';
import { useIcTransReactTableCols } from '../hooks/ItemCols';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    color: 'rgba(0,0,0,0.9)',
    height: '100%',
  },
  descript: { fontSize: '0.8rem', color: '#545454' },
  descriptVal: { fontSize: '0.85rem' },
}));

export default function ItemTransDetail(props) {
  const [moreData, setMoreData] = useState(null);

  const classes = useStyles();

  const icTransReactTableCols = useIcTransReactTableCols();

  const handleClose = () => {
    setMoreData(null);
  };

  const printValue = (dataObj, lookup, description, secondary, secondType) => {
    const isDate = description.toLowerCase().indexOf('date') !== -1;

    return (
      <div>
        <span className={classes.descript}>{`${description}:  `}</span>
        <span className={classes.descriptVal}>
          {`${isDate ? dateFormat(dataObj[lookup]) : dataObj[lookup]}${
            secondary && dataObj[secondary]
              ? ` (${
                  secondType !== 'date'
                    ? dataObj[secondary]
                    : dateFormat(dataObj[secondary])
                })`
              : ''
          } `}
        </span>
      </div>
    );
  };

  const printBasicValue = (
    dataObj,
    lookup,
    description,
    secondary,
    secondType
  ) => {
    const isDate = description.toLowerCase().indexOf('date') !== -1;

    return (
      <div>
        <span className={classes.descriptVal}>
          {`${isDate ? dateFormat(dataObj[lookup]) : dataObj[lookup]}${
            secondary && dataObj[secondary]
              ? ` (${
                  secondType !== 'date'
                    ? dataObj[secondary]
                    : dateFormat(dataObj[secondary])
                })`
              : ''
          } `}
        </span>
      </div>
    );
  };

  const { itemTransData } = props;

  const options = getTableOptions({
    cols: icTransCols,
    fileName: props.fileName,
  });
  options.downloadOptions.filterOptions.useDisplayedColumnsOnly = false;

  return (
    <>
      {moreData && (
        <Dialog
          open={moreData ? true : false}
          TransitionComponent={Transition}
          maxWidth="xl"
          scroll="body"
          keepMounted
        >
          <div style={{ padding: '16px 24px' }}>
            <Typography variant="h6">{moreData.TYPE_DESC}</Typography>
            <Typography variant="subtitle2">
              {dateFormat(moreData.TRANS_DATE)}
            </Typography>
          </div>

          <DialogContent>
            <Grid container spacing={3} direction="row" justifyContent="space-between">
              <Grid item>
                <Typography variant="subtitle1">Doc</Typography>
                {printBasicValue(moreData, 'DOCUMENT', 'Document')}
              </Grid>

              <Grid item>
                <Typography variant="subtitle1">Quantity</Typography>
                {printValue(moreData, 'ORIGINAL_QTY', 'Original Qty')}
                {printValue(moreData, 'TRAN_UOM_MULT', 'UOM Mult')}
                {printValue(moreData, 'STOCK_UOM', 'Stock UOM')}
              </Grid>

              <Grid item>
                <Typography variant="subtitle1">Reason</Typography>
                {printBasicValue(moreData, 'REASON_CODE', 'Reason Code')}
              </Grid>
              <Grid item>
                <Typography variant="subtitle1">Cost Info</Typography>

                {printValue(moreData, 'UNIT_COST_CURR', 'Current Cost')}
                {printValue(moreData, 'ADD_UNIT_COST', 'Additional Unit Cost')}
              </Grid>

              <Grid item>
                <Typography variant="subtitle1">Bin Info</Typography>

                {printValue(moreData, 'BIN', 'Bin')}
                {printValue(moreData, 'FROM_TO_BIN', 'From To Bin')}
                {printValue(moreData, 'FROM_TO_LOC', 'From To Loc')}
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button
              autoFocus
              variant="contained"
              color="primary"
              onClick={handleClose}
            >
              Close
            </Button>
          </DialogActions>
        </Dialog>
      )}
      <ReactTable cols={icTransReactTableCols} data={itemTransData} />
    </>
  );
}
