// @ts-nocheck
import { dateFormat } from "../utils/formatter";
import { Divider, MenuItem } from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers";
import {
  DateRangePicker,
  SingleInputDateRangeField,
} from "@mui/x-date-pickers-pro";
import moment from "moment";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import { useState } from "react";
import FilterListOffIcon from "@mui/icons-material/FilterListOff";

const getDateWithAddedTime = (date) => {
  const preDate = new Date(date);
  const userTimezoneOffset = preDate.getTimezoneOffset() * 60000;
  return new Date(preDate.getTime() - userTimezoneOffset);
};

const slotProps = {
  textField: {
    // helperText: "Filter Mode: Less Than",
    sx: { minWidth: "225px" },
    variant: "standard",
  },
};

const useDateRangeCol = (columnId, columnLabel, showDialog = false) => {
  const [dateFrom, setDateFrom] = useState(null);
  const [dateTo, setDateTo] = useState(null);

  const customSingleInput = (column) => {
    return (
      <SingleInputDateRangeField
        value={[dateFrom, dateTo]}
        onChange={([newDateFrom, newDateTo]) => {
          setDateFrom(newDateFrom);
          setDateTo(newDateTo);

          column.setFilterValue([newDateFrom, newDateTo]);
        }}
        slotProps={slotProps}
      />
    );
  };

  const customDateRangePicker = (column) => {
    return (
      <DateRangePicker
        value={[dateFrom, dateTo]}
        onChange={([newDateFrom, newDateTo]) => {
          setDateFrom(newDateFrom);
          setDateTo(newDateTo);

          column.setFilterValue([newDateFrom, newDateTo]);
        }}
        localeText={{ start: "Check-in", end: "Check-out" }}
        slotProps={slotProps}
        slots={{ field: SingleInputDateRangeField }}
      />
    );
  };

  const result = {
    accessorFn: (row) => new Date(row[columnId]), // convert to Date for sorting and filtering
    accessorKey: columnId,
    header: columnLabel,
    sortingFn: "datetime",
    enableSorting: true,
    // Cell: ({ cell }) => dayjs(cell.getValue()).format('MM/DD/YYYY'),
    Cell: ({ cell }) => {
      return moment.utc(cell.getValue()).format("MM/DD/YYYY") !== 'Invalid date' ?  moment.utc(cell.getValue()).format("MM/DD/YYYY") : ''  ;
    },
    Header: ({ column }) => <>{column.columnDef.header}</>, // custom header markup
    Filter: ({ column }) => (
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        {showDialog ? customDateRangePicker(column) : customSingleInput(column)}
      </LocalizationProvider>
    ),
    filterFn: (
      row,
      _columnIds,
      _ // _ is filterValue
    ) =>
      // filterByDateRangePicker(
      //   row,
      //   _columnIds,
      //   filterValue,
      // ),
      {
        // console.log('in filterFn', _columnIds);
        const rowStopDate = row.getValue(_columnIds);
        const filterFromDate = dateFrom
          ? dayjs(getDateWithAddedTime(dateFrom))
          : null;
        const filterToDate = dateTo
          ? dayjs(getDateWithAddedTime(dateTo))
          : null;

        if (filterFromDate && filterToDate) {
          return rowStopDate >= filterFromDate && rowStopDate <= filterToDate;
        }
        if (filterFromDate && !filterToDate) {
          return rowStopDate >= filterFromDate;
        }
        if (!filterFromDate && filterToDate) {
          return rowStopDate <= filterToDate;
        }
        return true;
      },
    columnFilterModeOptions: [],
    renderColumnActionsMenuItems: ({
      closeMenu,
      column,
      internalColumnMenuItems,
    }) => {
      return [
        ...internalColumnMenuItems.slice(0, 3),
        <Divider key="divider-for-date-range-picker-filter" />,

        <MenuItem
          disabled={!column.getIsFiltered()}
          key="item-1"
          onClick={() => {
            column.setFilterValue("");
            setDateFrom(null);
            setDateTo(null);
            // setAnchorEl(null);
            closeMenu();
          }}
        >
          <FilterListOffIcon />
          Clear filter
        </MenuItem>,
        ...internalColumnMenuItems.slice(4, 11),
      ];
    },
  };

  return result;
};

export default useDateRangeCol;
